import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import RequireAuth from "./AuthRouter";
import Container from "./Container";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";
import Dashboard from "../components/Dashboard";
import MyProfilePage from "../pages/MyProfilePage";
import SupportTable from "../components/tables/SupportTable";
import DocsTable from "../components/tables/DocsTable";
import DetailsTable from "../components/tables/DetailsTable";
import UsersTable from "../components/tables/UsersTable";
import AuditsTable from "../components/tables/AuditsTable";
import AdminCustomersTable from "../components/tables/AdminCustomersTable";
import LGPDPage from "../pages/LGPDPage";
import AdminQueriesTable from "../components/tables/AdminQueriesTable";
import AdminCustomerForm from "../components/forms/AdminCustomerForm";
import InvoicesTable from "../components/tables/InvoicesTable";
import QueriesCreditTable from "../components/tables/QueriesCreditTable";
import QueriesCadastralTable from "../components/tables/QueriesCadastralTable";
import AdminReportDetailedQueriesTable from "../components/tables/AdminReportDetailedQueriesTable";
import AdminRepresentativesTable from "../components/tables/AdminRepresentativesTable";
import AdminRepresentativeForm from "../components/forms/AdminRepresentativeForm";
import QueriesAutoTable from "../components/tables/QueriesAutoTable";
import PDFQueryPage from "../pages/PDFQueryPage";
import AdminHistoryTable from "../components/tables/AdminHistoryTable";
import ReportTable from "../components/tables/ReportTable";
import AdminPaymentsTable from "../components/tables/AdminPaymentsTable";
import AdminUsersTable from "../components/tables/AdminUsersTable";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <RequireAuth>
              <Container>
                <Outlet />
              </Container>
            </RequireAuth>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<UsersTable />} />
          <Route path="queries/details" element={<DetailsTable />} />
          <Route path="queries/credits" element={<QueriesCreditTable />} />
          <Route
            path="queries/cadastrals"
            element={<QueriesCadastralTable />}
          />
          <Route path="queries/autos" element={<QueriesAutoTable />} />
          <Route path="query/generate-pdf" element={<PDFQueryPage />} />
          <Route path="reports" element={<ReportTable />} />
          <Route path="invoices" element={<InvoicesTable />} />
          <Route path="audits" element={<AuditsTable />} />
          <Route path="admin/queries" element={<AdminQueriesTable />} />
          <Route
            path="admin/queries-history"
            element={<AdminReportDetailedQueriesTable />}
          />
          <Route
            path="admin/representatives"
            element={<AdminRepresentativesTable />}
          />
          <Route path="admin/customers" element={<AdminCustomersTable />} />
          <Route path="admin/history" element={<AdminHistoryTable />} />
          <Route path="admin/users" element={<AdminUsersTable />} />
          <Route path="admin/new-customer" element={<AdminCustomerForm />} />
          <Route
            path="admin/new-representative"
            element={<AdminRepresentativeForm />}
          />
          <Route path="admin/payments" element={<AdminPaymentsTable />} />
          <Route path="docs" element={<DocsTable />} />
          <Route path="profile" element={<MyProfilePage />} />
          <Route path="support" element={<SupportTable />} />
          <Route path="lgpd" element={<LGPDPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
